<template>
  <el-popover
      v-model="visible"
      width="280"
      popper-class="select-group-popover"
      trigger="click">
    <el-checkbox-group v-model="checkList">
      <el-checkbox
          v-for="item of allGroups"
          :key="item.id"
          :label="item.id"
          :disabled="groups.some(i => i.id === item.id)">
        <p class="label">{{ item.name }}</p>
        <span class="count">（{{ item.studentCount }}）</span>
      </el-checkbox>
    </el-checkbox-group>
    <div class="select-group-popover__footer">
      <div class="select-group-popover__confirm" @click="onConfirm">保存
      </div>
      <div class="select-group-popover__cancel" @click="visible=false">取消</div>
    </div>
    <div class="select-group-popover__select" :style="`width: ${width}px`"
         slot="reference">
      <p v-if="names">{{ names }}</p>
      <p class="col-999" v-else>未分组</p>
      <img :class="{'rotate':visible}" src="../../../assets/spread-2.png"/>
    </div>
  </el-popover>
</template>

<script>
import {joinGroups} from "@/api/group";

export default {
  name: "selectGroup",
  props: {
    classId: {
      type: String,
      required: true,
    },
    studentId: {
      type: String,
      required: true
    },
    allGroups: {
      type: Array,
      required: true
    },
    groups: {
      type: Array,
      required: true
    },
    width: {
      type: Number | String,
      default: 180
    }
  },
  data() {
    return {
      visible: false,
      groupList: [],
      checkList: []
    }
  },
  computed: {
    names() {
      return this.groups.map(item => item.name).join('；')
    },
  },
  watch: {
    visible() {
      this.checkList = this.allGroups.filter(item => this.groups.some(i => i.id === item.id)).map(item => item.id)
    }
  },
  methods: {
    async onConfirm() {
      try {
        // 剔除已经加入过的分组
        const ids = this.checkList.filter(item => !this.groups.some(i => i.id === item)).join()
        const res = await joinGroups(this.classId, this.studentId, ids)
        if (res.state === '11') {
          this.visible = false
          this.$message.success('保存成功')
          this.$emit('updated')
        }
      } catch (e) {
      }
    },
  }
}
</script>

<style lang="scss">
.select-group-popover {
  display: flex;
  flex-direction: column;
  padding: 20px 28px;

  .el-checkbox {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  .el-checkbox__inner {
    width: 20px;
    height: 20px;
    background-color: #f6f6f6;
    border-radius: 6px;

    &::after {
      width: 4px !important;
      height: 8px !important;
      left: 6px !important;
      top: 3px !important;
      border-width: 2px;
    }
  }

  .el-checkbox__label {
    display: flex;
    align-items: center;
    width: 208px;
    color: #333333;
    font-size: 15px;
    line-height: 22px;
    font-weight: normal;

    .label {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .count {
      flex-shrink: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
.select-group-popover {

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
  }

  &__confirm {
    width: 91px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    font-size: 15px;
    cursor: pointer;
    background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
    border-radius: 4px;

    &:hover {
      background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
    }
  }

  &__cancel {
    width: 91px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #333333;
    font-size: 15px;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    margin-left: 12px;

    &:hover {
      background-color: #F6F6F6;
    }
  }

  &__select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 28px;
    color: #333333;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #E5E5E5;
    padding: 4px 10px;
    margin: 0 28px;
    cursor: pointer;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      flex-shrink: 0;
      width: 9px;
      height: 6px;
      transform: rotate(0deg);
      transition: .3s;
    }

    .rotate {
      transform: rotate(180deg);
      transition: .3s;
    }
  }

}
</style>
