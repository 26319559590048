<template>
  <p class="highlight" :style="`font-size: ${fontSize}`">
    <span
        v-for="(item, index) of list"
        :key="index"
        :class="[item===keyword?'c-theme':'c-333']">{{ item }}</span>
  </p>
</template>

<script>
export default {
  name: "HighlightTextView",
  props: {
    fontSize: {
      type: String,
      default: '17px'
    },
    title: String,
    keyword: String,
  },
  computed: {
    list: function () {
      if (this.title) {
        return this.title.replace(new RegExp(`${this.keyword}`, 'g'), `%%${this.keyword}%%`).split('%%')
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.highlight {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .c-333 {
    color: #333333;
  }

  .c-theme {
    color: #309af2;
  }
}
</style>
